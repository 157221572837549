.col-md-3 {
    /* flex: 0 0 25%; */
    /* max-width: 25%; */
     position: relative;
    width: 100%; 
    /* padding-right: 15px;
    padding-left: 15px; */
     display: flex;
}
.ico-image {
    max-width: 100%;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}
.s-description{
    color: white;
    text-align:justify;
    letter-spacing: -1px;  
    padding: 10px;  
}
.s-description2{
    color: white;
    margin-left: 0px;
    padding-left: 0px;
    text-align: justify;
   font: lighter 24px/45px Helvetica, Sans-Serif; 
   letter-spacing: -1px;  
   background: rgb(0, 0, 0); /* fallback color */
   background: rgba(0, 0, 0, 0.7);
   padding: 10px;
}
.ul {
    list-style: none;
    text-align:left;
  }
  .ul .li:before {
    content: '✓';
  }

  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Optional: Set the container height to center vertically on the page */
  }
